<template>
  <!-- 设备基本信息 -->
  <div id="app">
    <!-- 设备基本信息 -->
    <el-collapse v-model="activeName" accordion :disabled="false">
      <el-collapse-item name="1">
        <template #title>
          <div class="title">
            <i class="el-icon-menu title"></i>
            <span> 设备信息</span>
          </div>
        </template>
        <div class="container">
          <div class="image-box">
            <el-image title="查看大图" style="height: 90px" :src="url" :preview-src-list="srcList">
              <div slot="error" class="img-error">
                <div class="img-error">图片未上传</div>
              </div>
            </el-image>
          </div>
          <div class="info-box">
            <div class="item" :title="'设备编号：'+deviceOfRow.id"> <span>设备编号：</span> {{deviceOfRow.id}}</div>
            <!-- <div class="item"> <span>公司名称：</span> {{deviceOfRow.companyValue}}</div> -->
            <div class="item" :title="item.proName+'：'+item.value" v-for="(item,index) in deviceOfRow.device" v-if="item.proName.indexOf('绑定视频')==-1&&item.proName.indexOf('设备ID')==-1&&item.proName.indexOf('设备图片')==-1"> <span>{{item.proName=="绑定公司"?"所属单位":item.proName}}：</span> {{item.value}}</div>
            <!-- 《总线物联网 4G 中继器的三个动态属性》 -->
            <div class="item" :title="'CCID：'+dynaVal.CCIDVal" v-if="deviceOfRow.typeId == 'IntelligentFireControlTerminal'&&(dynaVal.CCIDVal!=''||dynaVal.CCIDVal!=null||dynaVal.CCIDVal!=undefined)"> <b>CCID：</b> {{dynaVal.CCIDVal}}</div>
            <div class="item" :title="'IMSI：'+dynaVal.IMSIVal" v-if="deviceOfRow.typeId == 'IntelligentFireControlTerminal'&&(dynaVal.IMSIVal!=''||dynaVal.IMSIVal!=null||dynaVal.IMSIVal!=undefined)"> <b>IMSI：</b> {{dynaVal.IMSIVal}}</div>
            <!-- <div class="item" v-if="deviceOfRow.typeId == 'IntelligentFireControlTerminal'"> <b>信号值：</b> {{dynaVal.signalVal}}</div> -->
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

import companySystem from "@/api/managementApi/CompanyInfo";

import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

import BaseData from '@/assets/config/BaseData'

export default {
  props: ["deviceOfRow"],
  data() {
    return {
      baseUrl: BaseData.baseUrl,
      activeName: '1',
      companyList: [],
      systemList: [],
      url: '',
      srcList: [],
      // 《总线物联网 4G 中继器的三个动态属性》
      dynaVal: {
        CCIDVal: '',
        IMSIVal: '',
        signalVal: '',
      },
    }
  },
  created() {
    this.getCompanyAndSystem();
  },
  methods: {
    // 所有公司
    async getCompanyAndSystem() {
      // 获取公司
      const { data: company } = await companySystem.getCompanyInfo()
      // 获取系统
      const { data: system } = await noticeSysManagement.getNotifyBigSystemById()

      // 根据设备ID 查动态属性
      const { data: res } = await deviceInfoManagement.getDeviceInfoValueByDeviceId(this.deviceOfRow.id)
      //   console.log(res);
      if (this.deviceOfRow.typeId == 'IntelligentFireControlTerminal') {
        for (let i = 0; i < res.data.list.length; i++) {
          if (res.data.list[i].id == "CCID") {
            this.dynaVal.CCIDVal = res.data.list[i].value
          }
          if (res.data.list[i].id == "IMSI") {
            this.dynaVal.IMSIVal = res.data.list[i].value
          }
          if (res.data.list[i].id == "信号值") {
            this.dynaVal.signalVal = res.data.list[i].value
          }
        }
      }
      this.companyList = company.data.list;
      this.systemList = system.data.list;

      for (let i = 0; i < this.deviceOfRow.device.length; i++) {
        // 显示设备图片
        if (this.deviceOfRow.typeName.indexOf('烟感') != -1) {
          this.url = this.baseUrl + "deviceInfoIcon/电信无线烟感.png"
          this.srcList.push(this.url)
          console.log(this.url);
        } else if (this.deviceOfRow.device[i].proName == "设备图片") {
          this.url = this.baseUrl + this.deviceInfo.device[i].value
          this.srcList.push(this.url)
        }
        // if (this.deviceOfRow.device[i].proName == "设备图片") {
        //   this.url = this.baseUrl + this.deviceOfRow.device[i].value
        //   //   this.srcList[0] = this.baseUrl + this.deviceOfRow.device[i].value
        //   this.srcList.push(this.url)
        // }
        if (this.deviceOfRow.device[i].proName == "布防状态") {
          if (this.deviceOfRow.device[i].value == "0") {
            this.deviceOfRow.device[i].value = "不布防"
          } else if (this.deviceOfRow.device[i].value == "1") {
            this.deviceOfRow.device[i].value = "布防"
          }
        }
        if (this.deviceOfRow.device[i].proName == "是否启用") {
          if (this.deviceOfRow.device[i].value == "0") {
            this.deviceOfRow.device[i].value = "否"
          } else if (this.deviceOfRow.device[i].value == "1") {
            this.deviceOfRow.device[i].value = "是"
          }
        }
        if (this.deviceOfRow.device[i].proName == "微信通知"
          || this.deviceOfRow.device[i].proName == "语音通知"
          || this.deviceOfRow.device[i].proName == "短信通知") {
          if (this.deviceOfRow.device[i].value == "0") {
            this.deviceOfRow.device[i].value = "关闭"
          } else if (this.deviceOfRow.device[i].value == "1") {
            this.deviceOfRow.device[i].value = "开启"
          }
        }
        if (this.deviceOfRow.device[i].proName == "所属系统") {
          for (let j = 0; j < this.systemList.length; j++) {
            if (this.deviceOfRow.device[i].value == this.systemList[j].id) {
              this.deviceOfRow.device[i].value = this.systemList[j].bigSystemName
            }
          }
        }
        if (this.deviceOfRow.device[i].proName == "绑定公司") {
          for (let j = 0; j < this.companyList.length; j++) {
            if (this.deviceOfRow.device[i].value == this.companyList[j].id) {
              this.deviceOfRow.device[i].value = this.companyList[j].name
            }
          }
        }
      }
    },
  }

}
</script>

<style lang="scss" scoped>
/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
.title {
  background: #eff0f0;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: cadetblue;
  padding-left: 5px;
}
.container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  .image-box {
    margin: 5px 5px 0 20px;
    width: 10%;
    // border: 1px solid red;
    border-right: 1px solid #ccc;
    cursor: pointer;
  }

  .info-box {
    width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    .item {
      box-sizing: border-box;
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
      width: 20%;
      font-size: 16px;
      border-radius: 5px;

      overflow: hidden; //隐藏文字
      text-overflow: ellipsis; //显示 ...
      white-space: nowrap; //不换行

      span {
        font-weight: 600;
        margin-right: 10px;
      }
    }
    .item:hover {
      transition: 1s;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
.img-error {
  color: #b9bbbb;
  margin: 20px 45px;

  div {
    height: 60px;
    font-size: 18px;
    line-height: 60px;
  }

  .error {
    font-size: 20px;
  }
}
</style>