<template>
  <div id="app">
    <!-- 设备基本信息 -->
    <div>
      <DeviceBaseInfo :deviceOfRow="deviceOfRow" />
    </div>

    <!-- 设备基本信息 -->
    <el-collapse v-model="optionOpen" accordion :disabled="false">
      <el-collapse-item name="1">
        <template #title>
          <div class="title">
            <i class="el-icon-menu title"></i>
            <span> 设备操作记录</span>
          </div>
        </template>
        <div>
          <el-table :data="commandList" style="width: 100%">
            <el-table-column type="index" label="序号" width="70" align="center">
            </el-table-column>
            <el-table-column prop="operator" label="操作者" align="center">
            </el-table-column>
            <el-table-column prop="value" label="指令" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="时间" align="center">
            </el-table-column>
          </el-table>
          <div>
            <el-pagination align="center" background :current-page.sync="current" :page-size="limit" layout="total, prev, pager, next, jumper" :total="total" @current-change="pagination()">
            </el-pagination>
          </div>
        </div>

      </el-collapse-item>
    </el-collapse>

  </div>
</template>

<script>

// 设备基本信息组件
import DeviceBaseInfo from '@/views/manage/aSingleDeviceInformation/DeviceBaseInfo'

import Command from '@/api/mapBuidingApi/Command'

export default {
  name: 'HistoricalData',
  props: ["deviceOfRow"],
  components: {
    DeviceBaseInfo
  },
  data() {
    return {
      // 默认展开
      optionOpen: '1',
      // 页码
      logRuleList: [],
      commandList: [],

      limit: 10,
      current: 1,
      total: 0,
    }
  },

  created() {
    this.getDeviceCommandHistory()
  },
  methods: {
    getDeviceCommandHistory() {
      Command.getDeviceCommandHistory(this.deviceOfRow.id, this.current, this.limit).then(res => {
        this.commandList = res.data.data.list;
        for (const i in this.commandList) {
          if (this.commandList[i]["status"] == null) {
            this.commandList[i]["status"] = "未知"
          }
        }
        this.total = res.data.data.total
      })
    },
    pagination() {
      this.getDeviceCommandHistory()
    },

  }

}
</script>

<style lang="scss" scoped>
/deep/.el-collapse-item__header {
  letter-spacing: 3px;
  background: #eff0f0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
/deep/.el-collapse-item__content {
  padding: 0;
}
/deep/.el-collapse-item__wrap {
  padding: 10px;
  background: #fff;
}
.title {
  background: #eff0f0;
  height: 50px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: cadetblue;
  padding-left: 5px;
}
/deep/.el-pagination {
  margin: 20px 0;
  position: static;
}
</style>